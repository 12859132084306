.btn-disable:not(.btn-link), .btn-disabled:not(.btn-link)  {
    background-color: #777;
    color: white;
    pointer-events: none;
    border-color: #777;
    opacity: 1;
}

button, .button, .btn {
    font-weight: bold;
    border-radius: $border-radius;
    font-size: $body-copy-size;
    padding: 18px 36px;
}

button, a {
    &:focus {
        @include bootstrap-focus-shadow();
        outline: none;
        border-radius: $border-radius;
    }
}

.btn-sm {
    padding: 8px 24px;
}

.btn-link {
    padding: 0;
}

.btn-link-callout {
    text-decoration: underline;
    color: $brand-tertiary-color;
    &:hover {
        color: $brand-primary-color;
    }    
    &:active, &:focus {
        text-decoration: underline;
        color: $brand-tertiary-color;
    }
}

.btn-primary {
    text-decoration: none !important;
    color: white !important;
}

.btn-secondary {
    text-decoration: none !important;
    &:focus {
        @include bootstrap-focus-shadow();
        &:hover {
            border: 1px solid white;
            padding: 17px 34px;
            &.btn-sm {
                padding: 7px 23px;
            }
        }
    }
}

.primary-invert {
    background-color: #fff !important;
    color: $brand-primary-color !important;
    border-color: white;
    &:hover {
        background-color: $brand-primary-color !important;
        color: #fff !important;
        &:focus {
            border: solid 1px #fff !important;
        }
    }
}


.App.unauthencated {
    background: $brand-primary-color;
    position: relative;
    height: 100%;

    &.start-page {
        background: white;
    }
}
.unauth-content {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
}
.unauth-header .row {
    position: relative;
    z-index: 4;
}
.unauth-header-logo {
    margin-left: calc(3rem + 15px);
    padding-top: 25px;
    width: 150px;
    img {
        width: 100%;
    }
    @include media-breakpoint-down(lg) {
        margin-left: 15px;
    }
    @include media-breakpoint-down(md) {
        width: 120px;
        img {
            width: 100%;
        }
    }
    @include media-breakpoint-down(sm) {
        margin-left: 0;
    }
    a {
        display: inline-block;
    }
    a:focus {
        @include bootstrap-focus-shadow(white);
        border-radius: 0;
    }
}
.unauth {
    background: $brand-primary-color;
    color: $brand-high-contrast-color;
    h1 {
        font-weight: normal;
        margin-bottom: $spacer;
        color: $brand-high-contrast-color;
        font-size: 32px;
    }
    a {
        color: $brand-primary-color;
        text-decoration: underline;
    }
    .form-group {
        margin-bottom: $spacer * 0.5;
    }
    .unauth-welcome {
        display: none;
    }

    .unauth-character-left-desktop {
        display: none;

        @include media-breakpoint-up(lg) {
            display: block;
            position: absolute;
            overflow: hidden;
            left: -240px;
            top: 80px;
            height: 635px;
            width: 480px;
            svg {
                width: 480px;
                height: 564px;
            }
        }
        @include media-breakpoint-up(xl) {
            left: -250px;
            top: 150px;
            height: 660px;
            width: 560px;
            svg {
                width: 560px;
                height: 658px;
            }
        }
    }
    .unauth-character-right-desktop {
        display: none;
        @include media-breakpoint-up(lg) {
            display: block;
            position: absolute;
            overflow: hidden;
            right: -260px;
            top: -100px;
            height: 635px;
            width: 560px;
            svg {
                width: 560px;
                height: 587px;
            }
        }
        @include media-breakpoint-up(xl) {
            right: -260px;
            top: -60px;
            height: 635px;
            width: 600px;
            svg {
                width: 600px;
                height: 600px;
            }
        }
    }
    .unauth-character-right-mobile {
        display: block;
        position: absolute;
        overflow: hidden;
        right: -400px;
        top: -100px;
        height: 635px;
        width: 600px;
        svg {
            width: 600px;
            height: 600px;
        }
        @include media-breakpoint-down(xs) {
            display: none;
        }
        @include media-breakpoint-up(lg) {
            display: none;
        }
    }

    .unauth-header-wrapper {
        &.landing-wrapper {
            background: $brand-primary-color;
            position: relative;
            z-index: 4;
        }
    }

    .mobile-welcome {
        &.unauth-header-wrapper {
            .unauth-welcome {
                display: block;
                top: 20px;
                transform: none;
                position: relative;
                z-index: 3;
                margin: 0 auto;
                max-width: 600px;
                width: calc(100% + #{$spacer});
                // width: 100%;
                @include media-breakpoint-up(md) {
                    width: calc(60% + #{$spacer});
                }
                .unauth-description {
                    color: $brand-high-contrast-color;
                    border-left: 2px solid $brand-secondary-color;
                    padding-left: $spacer;
                }

                h1 {
                    color: $brand-high-contrast-color;
                    font-size: 59px;
                    line-height: 48px;
                    @include media-breakpoint-down(md) {
                        font-size: 48px;
                        line-height: 42px;
                    }
                }
            }
            @include media-breakpoint-down(xs) {
                .unauth-welcome {
                    margin: 0;
                }
            }
            @include media-breakpoint-down(md) {
                .unauth-description {
                    display: none;
                }
            }
        }
        &.unauth-component-wrapper {
            margin-top: 50px;
        }
    }

    .unauth-component-wrapper {
        color: $brand-high-contrast-color;
        position: relative;
        z-index: 3;
        margin: 0 auto;
        margin-top: $spacer * 3;
        max-width: $default-width-sm;
        width: calc(100% - #{$spacer} - #{$spacer});

        &.landing-wrapper {
            width: 100%;
            max-width: 100%;
            position: static;
            // z-index: unset;

            @include media-breakpoint-down(sm) {
                position: relative;
            }
        }

        @include media-breakpoint-up(md) {
            width: 60%;
            @media only screen and (max-height: $mediaHeight) {
                margin-top: $spacer;
            }
        }
    }
}

.unauth-web-page {
    background: white;
    color: $brand-high-contrast-color;
    h1 {
        font-weight: normal;
        margin-bottom: $spacer;
        color: $brand-high-contrast-color;
        font-size: 32px;
    }
    a {
        color: $brand-primary-color;
        text-decoration: underline;
    }
    .form-group {
        margin-bottom: $spacer * 0.5;
    }
    .unauth-welcome {
        display: none;
    }

    
    .unauth-web-page-header-wrapper {
        &.landing-wrapper {
            background: $brand-primary-color;
            position: relative;
            z-index: 4;
            min-height: 50px;
        }
        &::before{
            content: '';
            position: absolute;
            width: 100%;
            height: 9vh;
            left: 50%;
            background: $brand-primary-color;
            border-radius: 50%;
            z-index: -1;
            transform-origin: bottom;
            transform: translate(-50%) scale(4);
        }        
    }

    .unauth-web-page-component-wrapper {
        color: $brand-high-contrast-color;
        position: relative;
        z-index: 3;
        margin: 0 auto;
        margin-top: $spacer * 3;
        max-width: $default-width-sm;
        width: calc(100% - #{$spacer} - #{$spacer});

        &.landing-wrapper {
            width: 100%;
            max-width: 100%;
            position: static;
            // z-index: unset;

            @include media-breakpoint-down(sm) {
                position: relative;
            }
        }

        @include media-breakpoint-up(md) {
            width: 60%;
            @media only screen and (max-height: $mediaHeight) {
                margin-top: $spacer;
            }
        }
    }
}

.signup-body-wrapper,
.unauth-body-wrapper {
    background: white;
    color: $copy-primary-color;
    padding: $spacer * 1.5;
    border-radius: $border-radius;
    position: relative;
    height: auto;
    @include media-breakpoint-up(md) {
        padding: $spacer * 2 $spacer * 3;
        position: relative;
        // padding-bottom: 120px !important;
    }
    a {
        color: $brand-tertiary-color;
    }
    h1 {
        color: $brand-primary-color;
    }
    .signup-body-buttons {
        display: inline-flex;
        text-align: right;
    }
}

.inlineText {
    white-space: nowrap;
}


.unauth-body-footer,
.password-submit-wrapper,
.next-text-media {
    text-align: center;
}

.workflow .next-text-media {
    position: static !important;
    width: 100% !important;
}

.signup-body-wrapper {
    @media only screen and (max-height: $mediaHeight) {
        min-height: 0;
        padding-bottom: 2rem;
    }
}

.confirm-email-admin .signup-body-wrapper {
    @include media-breakpoint-up(md) {
        min-height: calc(
            100vh - (#{$heightBodyTopPadding} + #{$heightHeader} + #{$heightFooter} + #{$heightProgressBar + 170px})
        );
    }
}

.unauth-body-wrapper {
    min-height: auto;
    padding-bottom: 2rem !important;
    .password-requirements {
        @include media-breakpoint-down(xs) {
            padding-top: 10px;
        }
    }
}

.unauth-footer {
    background: $brand-primary-color;
    margin: 0;
    padding: 0;
    padding-top: $spacer * 3;
    padding-bottom: 13px;
    color: $brand-high-contrast-color;
    position: relative;
    bottom: 0;

    &.landing-wrapper a {
        color: $brand-primary-color;
    }
    img {
        height: 33px;
        object-fit: scale-down;
    }
    @include media-breakpoint-down(sm) {
        height: 75px;
        padding-top: $spacer * 2;
        font-size: 10px;
        img {
            height: 26px;
        }
    }

    a:focus {
        @include bootstrap-focus-shadow(white);
    }
}

.unauth-gradient {
    &:after {
        content: "";
        display: block;
        background: transparent;
        background: radial-gradient(ellipse, rgba(255, 255, 255, 0.2) 0%, rgba(0, 92, 163, 0) 60%);
        position: absolute;
        top: 0;
        right: 100px;
        width: 600px;
        height: 700px;
        border-radius: 100%;
        @include media-breakpoint-down(lg) {
            right: 0;
        }
    }
    @include media-breakpoint-down(md) {
        display: none;
    }
}


.header-logo {
    width: 110px;
    margin-top: 0px;
    img {
        width: 100%;
    }
    @include media-breakpoint-down(md) {
        width: 120px;
        img {
            width: 100%;
        }
    }
    a {
        display: inline-block;
    }
}

.header-row {
    height: 37px;
}

header {
    background: $brand-primary-color;
    color: #FFF;
    border-bottom: $spacer solid white;
    position: relative;
    @include media-breakpoint-up(md) {
        min-height: 200px;
    }
    @include media-breakpoint-down(sm) {
        border-bottom: $spacer solid white;
        overflow: hidden;
    }
    &:after {
        content: "";
        display: block;
        background: transparent;
        background: radial-gradient(ellipse at bottom, rgba(255, 255, 255, 0.2) 0%, rgba(0, 92, 163,0) 60%);
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
        bottom: 0px;
        width: 80vw;
        height: 100px;
        border-radius: 50% / 100%;
        border-bottom-left-radius: 0;
        border-bottom-right-radius: 0;
    }
    .header-image-right {
        display: block;
        position: absolute;
        right: 0;
        max-height: 220px;
        bottom: 0;
        z-index: 0;
        img {
            max-height: 220px;
        }
    }
    .header-image-desktop {
        display: none;
        @include media-breakpoint-up(md) {
            display: block;
            position: absolute;
            right: 0;
            max-height: 180px;
            bottom: 0;
            img {
                max-height: 180px;
            }
        }
    }
    .header-character-desktop {
        display: none;
        @include media-breakpoint-up(xl) {
            display: block;
            position: absolute;
            left: -180px;
            height: 155px;
            bottom: 0px;
            overflow: hidden;
            svg {
                width: 550px;
                height: 600px;
            }
        }
    }
    .header-character-mobile {
        display: none;
        @include media-breakpoint-down(sm) {
            display: block;
            position: absolute;
            right: -340px;
            height: 400px;
            top: -60px;
            overflow: hidden;
            svg {
                width: 540px;
                height: 540px;
            }
        }
    }
    .header-donut-desktop {
        display: block;
        width: 500px;
        height: 500px;
        top: -260px;
        right: -155px;
        position: absolute;
        z-index: 0;
        @include media-breakpoint-down(sm) {
            display: none
        }
    }
    .header-message {
        position: relative;
        z-index: 1;
    }
    .header-hello {
        @include media-breakpoint-down(sm) {
            padding: $spacer 0 $spacer * 3 0;
        }
    }
    .header-custom {
        color: white;
        padding-top: $spacer;
        letter-spacing: 1px;
        padding-bottom: 0;
        @include media-breakpoint-down(sm) {
            padding-top: 0;
        }
        h1 {
            color: white;
            font-weight: normal;
            font-size: 40px;
            @include media-breakpoint-down(sm) {
                font-size: 28px;
            }
        }
    }
    .header-custom-profile {
        @include media-breakpoint-down(sm) {
            padding-top: $spacer;
            padding-bottom: 0;
        }
    }
    @include media-breakpoint-down(md) {
        .header-signout {
            // padding: 0;
            .col-6 {
                padding: 0;
            }
        }
    }
    @include media-breakpoint-down(sm) {
        .header-signout .name {
            display: none;
        }
    }
}

.header-dashboard {
    @include media-breakpoint-down(sm) {
        padding-bottom: $spacer * 3 !important;
    }
}

.nav-name {
    display: inline-block;
    padding-left: 3rem;
    &.unauth-header {
        padding-left: 0;
        color: white;
    }
}
.nav-language {
    display: inline-block;
    .header-languages {
        margin-top: 0;
    }
}

.custom-header-mobile {
    font-size: 13px;
    @include media-breakpoint-up(xl) {
        display: none;
    }
}

.header-profile {
    .header-character-mobile {
        @include media-breakpoint-down(sm) {
            height: 220px;
            bottom: 0;
        }
    }
}


/**
 * @file typography.scss
 *
 * Typography used throughout the site.
 *
 */

@import "~bootstrap/scss/bootstrap";
@import 'src/styles/base/variables.scss';

$UIFont: 'Eudoxus Sans', 'Open Sans', 'Helvetica';
$UIFont-regular: 400;
$UIFont-semi-bold: 600;
$UIFont-bold: 700;

@font-face {
    font-family: 'Eudoxus Sans';
    src: url(../../assets/fonts/EudoxusSans-Regular.woff2) format('woff2'),
      url(../../assets/fonts/EudoxusSans-Regular.woff) format('woff');
    font-weight: 400;
    font-style: normal;
    font-display: block;
}

@font-face {
    font-family: 'Eudoxus Sans';
    src: url(../../assets/fonts/EudoxusSans-Bold.woff2) format('woff2'),
      url(../../assets/fonts/EudoxusSans-Bold.woff) format('woff');
    font-weight: $UIFont-semi-bold;
    font-style: normal;
    font-display: block;
}

@font-face {
    font-family: 'Open Sans';
    font-style: normal;
    font-display: swap;
    src: url('../../assets/fonts/OpenSans-VariableFont_wdth.ttf') format('truetype');
}

@font-face {
    font-family: 'Open Sans';
    font-style: italic;
    font-display: swap;
    src: url('../../assets/fonts/OpenSans-Italic-VariableFont_wdth.ttf') format('truetype');
}
 
//
// Regular body copy.
//
body {
    font-family: $UIFont;
    color: $copy-primary-color;
}
  
  
//
// Headings 
//
h1,
h2,
h3,
h4,
h5,
h6,
.h1,
.h2,
.h3,
.h4,
.h5,
.h6 { 
    font-weight: $UIFont-semi-bold;
    color: $brand-primary-color;
    margin:0;
}

h1,.h1 {
    font-size: 40px;
    font-weight: $UIFont-regular;
    @include media-breakpoint-down(sm) {
        font-size: 28px;
    }
}
h2, .h2 {
    font-size: 40px;
    color: black;
    font-weight: $UIFont-regular;
    @include media-breakpoint-down(sm) {
        font-size: 28px;
    }
}
h3, .h3 {
    font-size: 19px;
    @include media-breakpoint-down(xs) {
        font-size: 15px;
    }
}
h4, .h4 {
    font-size: 15px;
    @include media-breakpoint-down(xs) {
        font-size: $body-copy-size;
    }
}

.surveys-h1, .ancestry-h1 {
    font-size: 36px;
    // font-weight: bold;
    margin-bottom: $spacer * 0.5;
}

.side-heading { 
    font-weight: $UIFont-semi-bold;   
    font-size: 26px;
    @include media-breakpoint-down(sm) {
        font-size: 20px;
    }
}

.color-dark {
    color: $copy-primary-color;
}

.color-light {
    color: #FFF;
}

// . {
//     color: $copy-primary-color;
//     @include media-breakpoint-down(md) {
//         color: #FFF;
//         a {
//             color: #FFF !important;
//         }   
//     }
// }

.color-brand {
    color: $brand-primary-color;
}

  
a[x-apple-data-detectors] {
    color: inherit !important;
    text-decoration: none !important;
    font-size: inherit !important;
    font-family: inherit !important;
    font-weight: inherit !important;
    line-height: inherit !important;
}
  

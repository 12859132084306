.consent {
    font-size: 12px;
    label {
        font-size: 14px;
    }
    h1 {
        font-weight: 400;
        margin-bottom: 1rem;
        font-size: 32px;
        color: $brand-primary-color;
    }
    ol,ul {
        padding-left: 16px;
        li {
            margin-bottom: 2px;
        }
    }
    .btn-disable {
        border-color: #777;
    }
    .consent-component-esign {
        .consent-sig-wrapper {
            .consent-sig-aknowledgement {
                font-size: 16px;
                font-weight: bold;
            }
        }
    }
    .consent-component-qn-radio {
        font-size: $body-copy-size;
        .consent-radio-wrapper {
            @include media-breakpoint-up(lg) {
                padding: 0 2rem;
            }
        }
        .consent-radio-qn {
            padding: 20px 0 10px 0;
            border-bottom: 1px solid $border-color;
            float: left;
            width: calc( 100% - 100px );
        }
        .consent-radio-answer-wrapper {
            float: right;
            width: 90px;
            .consent-radio-answer {
                float: left;
                padding: 0 0.5rem;
                label {
                    text-transform: capitalize;
                }
            }
        }
    }
    .consent-component-qn {
        .questionnaire .next-question {
            margin-bottom: 0;
            .button-next-question {
                width: auto;
                padding: 8px 50px;
                float: none !important;
                &:hover {
                    background-color: $brand-primary-color-dark !important;
                }
                &[disabled] {
                    opacity: 1;
                    background-color: #777 !important;
                    pointer-events: none !important;
                    border-color: #777 !important;
                    &:hover {
                        text-decoration: none;
                    }
                }
            }
        }
    }
    .consent-component-video {
        video {
            width: 100%;
        }
    }
    .knowledge-check {
        .knowledge-check-title {
            font-size: 1.25rem;
            font-weight: 600;
            margin-bottom: 1rem;
            button {
                margin-left:10px;
            }
        }
    }

    .consent-footer {
        width: 100%;
        display: block;
        text-align: center;
        padding: $spacer*0.5 $spacer;
        p {
            font-size: 12px;
            line-height: 14px;
            max-width: 360px;
            margin: 0 auto;
        }
        .btn-sm {
            padding: 8px 50px;
        }
        .consent-sig-placeholder {
            background: white;
            height: 50px;
            width: 360px;
            max-width: 100%;
            margin: 0 auto;
            &:focus {
                outline: none;
                @include bootstrap-focus-shadow();
            }
            div {
                padding-top: 16px;
                font-size: 12px;
            }
        }
        @media only screen and (max-height: $mediaHeight) {
            .consent-sig-placeholder {
                border: 2px solid $background-primary-color;
            }
            .btn-sm {
                padding: 18px 24px;
                display: block;
                width: 100%;
            }
        }
        @include media-breakpoint-down(xs) {
            position: static;
            width: 100%;
            margin: 0 -1.5rem;
            width: calc(100vw - 2rem);
            .consent-sig-placeholder {
                border: 2px solid $background-primary-color;
            }
            .btn-sm {
                padding: 18px 24px;
                display: block;
                width: 100%;
            }
        }
    }
}

// this component already has a large wrapper for footer
.consent-component-esign {
    .consent-subcomponents {
        min-height: 0 !important;
    }
}

.consent-back {
    position: absolute;
    bottom: -26px;
    left: 3rem;
    z-index: 2;
    color: white;
    text-align: center;
    @include media-breakpoint-down(sm) {
        left: 24px;
    }
    button {
        color: white;
        padding-left: 14px;
        padding-right: 2px;
        &:hover, &:active, &:focus {
            color: white;
        }
        &:focus {
            @include bootstrap-focus-shadow(white);
        }
        &:before {
            content: "◄";
            position: absolute;
            top: -1px;
            left: 2px;
        }
    }
}

.consent-modal, .consent-sig-modal {
    .modal-content {
        width: calc(100% - 1rem);
    }
    .modal-body {
        padding-top: 0;
    }
    .h4 {
        font-size: 19px !important;
    }
    .btn-sm {
        padding: 8px 50px;
        &:focus:hover {
            padding: 8px - 1  50px - 1;
        }
        @include media-breakpoint-down(xs) {
            padding: 8px 24px;
            display: block;
            width: 100%;
        }
    }
}

.collapsible-section {
    h2, h3 {
      font-size: 14px;
      margin: 3px 0 10px;
      font-weight: bold;
    }
    border-top: 1px solid #CCDEED;
    border-radius: 0;
    padding-top: 15px;
    .accordion-button {
        background-color: transparent;
        margin-top: -10px;
        margin-right: -10px;
        display: flex;
        align-items: center;
        justify-content: center;
        &:focus {
            border: none;
            box-shadow: none;
            outline: none;
        }
        &:focus-visible {
            box-shadow: 0 0 0 0.2rem rgba(0, 161, 239, 0.8);
            outline: none;
            border-radius: 0.5rem;
        }
        &:after {
            content: "";
            width: 10px;
            height: 10px;
            border: solid $brand-primary-color;
            border-width: 0 2px 2px 0;
            transform: rotate(45deg);
        }
        &.active {
            &:after {
                transform: rotate(225deg) translateY(-25%) translateX(-25%);
            }
        }
    }
    .expand-button {
        font-size: 12px;
        color: $brand-primary-color;
        font-weight: normal;
        border: 0;
        margin-left: -12px;
        padding: 5px 12px;
        margin-top: -5px;
        margin-bottom: -5px;
        background-color: transparent;
        text-decoration: underline;
        text-underline-offset: 3px;
        &:focus {
            border: none;
            box-shadow: none;
            outline: none;
        }
        &:focus-visible {
            box-shadow: 0 0 0 0.2rem rgba(0, 161, 239, 0.8);
            outline: none;
            border-radius: 0.5rem;
        }
    }
}

@import 'styles/base/bootstrap-variable-overrides.scss';

@import "~bootstrap/scss/bootstrap";
@import "~bootstrap/scss/mixins/_breakpoints";

@import 'styles/base/variables.scss';

@import 'styles/base/usability.scss';
@import 'styles/base/reset.scss';
@import 'styles/base/flex.scss';
@import 'styles/base/typography.scss';
@import 'styles/base/global.scss';
@import 'styles/base/utils.scss';
@import 'styles/base/icons.scss';
@import 'styles/base/bootstrap_overwrite.scss';
@import 'styles/base/form.scss';
@import 'styles/base/iphone_zoom_form.scss';
@import 'styles/base/ie_support.scss';


@import 'styles/components/dashboard-loader.scss';
@import 'styles/components/dashboard-survey.scss';
@import 'styles/components/header.scss';
@import 'styles/components/footer.scss';
@import 'styles/components/nav.scss';
@import 'styles/components/signup-progress.scss';
@import 'styles/components/get-started.scss';
@import 'styles/components/action-cards.scss';
@import 'styles/components/desktop-cards.scss';
@import 'styles/components/suggested-surveys.scss';
@import 'styles/components/buttons.scss';
@import 'styles/components/quick-polls.scss';
@import 'styles/components/surveys.scss';
@import 'styles/components/spinner.scss';
@import 'styles/components/profile.scss';
@import 'styles/components/modal.scss';
@import 'styles/components/unauth-pages.scss';
@import 'styles/components/logout.scss';
@import 'styles/components/consent.scss';
@import 'styles/components/ancestry.scss';
@import 'styles/components/app-policies.scss';
@import 'styles/components/legal.scss';
@import 'styles/components/verification.scss';
@import 'styles/components/_ancestry-message.scss';
@import 'styles/components/global-error-modal.scss';
@import 'styles/components/landing-page.scss';
@import 'styles/components/questionnaire.scss';
@import 'styles/components/confirm-email.scss';
@import 'styles/components/signature.scss';
@import 'styles/components/registrationflow.scss';
@import 'styles/components/info-popup.scss';
@import 'styles/components/video-player.scss';
@import 'styles/components/workflow.scss';
@import 'styles/components/emr.scss';
@import 'styles/components/language-switch';
@import 'styles/components/web-page';
@import 'styles/components/not-found-page';

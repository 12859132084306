.workflow-component-wrapper {
    &.bg-emr {
        margin: -1.5rem;
    }
    .emr-wrapper {
        background: white;
        padding: 1.5rem;
        border-radius: $border-radius;
    }
}

.emr-confirm-buttons {
    > button {
        margin-right: 0.5rem;

        &:last-child {
            margin-right: 0;
        }

        @include media-breakpoint-down(sm) {
            margin-bottom: 0.5rem;
        }
    }
}

.emr-search-form {
    input {
        padding-right: 60px !important;
    }
    button[type="submit"].btn {
        border-radius: 0 0.25rem 0.25rem 0;
        position: absolute;
        right: -1px;
        top: 0;
        height: 48px;
        padding: 0.375rem 1rem;
        z-index: 9;
        > svg {
            width: 16px;
        }
    }
}

.emr-search-container {
    max-height: 30rem;
    overflow-y: auto;
}

.emr-list-item {
    display: flex;
    align-items: center;
    padding-top: 1rem;
    flex-wrap: wrap;
    .emr-list-item-image {
        width: 100px;
        img {
            width: 100%;
        }
        @include media-breakpoint-down(sm) {
            width: 100%;
            text-align: center;
            padding-bottom: 10px;
            img {
                max-width: 60px;
            }
        }
    }
    .emr-list-item-text {
        width: calc(100% - 230px);
        padding: 0 1rem;
        @include media-breakpoint-down(sm) {
            width: 100%;
            text-align: center;
        }
    }
    .emr-list-item-button {
        text-align: right;
        width: 130px;
        @include media-breakpoint-down(sm) {
            width: 100%;
            text-align: center;
        }
    }
}

.emr-alert {
    display: block;
    line-height: 5rem;
    text-align: center;
    width: 5rem;
    height: 5rem;
    border: 3px solid #333;
    color: #333;
    font-weight: bold;
    font-size: 4rem;
    border-radius: 50%;
    background: white;

    @include media-breakpoint-down(sm) {
        line-height: 3rem;
        width: 3rem;
        height: 3rem;
        font-size: 2rem;
    }
}

.emr-connect-button {
    margin-left: auto;
    margin-bottom: auto;
    margin-top: auto;

    @include media-breakpoint-down(sm) {
        margin-right: auto;
        margin-top: 1rem;
    }
}


.emr-existing-connections {
    padding: 1.5rem;
    background: darken($background-primary-color, 2%);
    border-radius: $border-radius;
    .emr-existing-connections-list {
        
    }

}

.emr-skip-buttons {
    text-align: right;
    @include media-breakpoint-down(sm) {
        text-align: center;
    }
    
    button {
        background: darken($background-primary-color, 2%) !important;
    }
}
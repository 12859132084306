.nav-desktop {
    display: none;
    @include media-breakpoint-up(md) {
        display: block;
        a:focus {
            @include bootstrap-focus-shadow(white);
        }
    }
}
.nav-mobile {
    display: block;
    width: 100%;
    @include media-breakpoint-up(md) {
        display: none;
    } 
}

nav {
    padding: 0.5rem 0;
    ul {
        margin: 0;
        padding: 0;
        li {
            display: inline-block;
            list-style-type: none;
            padding: 0px 2px;
            margin: 0;
        }
    }
    .nav-img {
        display: none;
    }
    @include media-breakpoint-up(md) {
        a, a:focus, a:active {
            color: white;
            font-weight: $UIFont-semi-bold;
            text-transform: capitalize;
            padding: $spacer * .5 $spacer;
            border-radius: 16.5px;
            &:hover, &.active {
                background: white !important;
                color: $brand-primary-color !important;
            }
            &:focus {
                border-width: 0.1rem;
                border-color: $brand-tertiary-color;
                border-style: solid;
                padding: $spacer * .5 - .1 $spacer - .1;
            }
        }
    }
    @include media-breakpoint-down(sm) {
        z-index: 4;
        background-color: #FFF;
        position: fixed;     
        max-width: 100%;
        width: 100%;
        bottom: 0;
        left: 0;
        padding: 0;
        border-top: 2px solid $background-primary-color;
        .container {
            margin: 0 auto;
            padding: 0;
            text-align: center;
        }
        li {
            float: left;
            display: block;
            width: calc(25% - 40px);
            margin: 0 20px !important;
        }
        a {
            position: relative;
            display: block;
            font-size: 13px;
            width: 100%;
            padding: 3px 0;
            color: $brand-primary-color;
            span {
                height: 20px;
                display: inline-block;
            }
        }    
        a.active {
            // font-weight: bold;
            color: $copy-primary-color;
            &:after {
                content: "";
                width: 100%;
                background: $brand-primary-color;
                height: 5px;
                display: block;
                position: absolute;
                bottom: 0;
                border-radius: 5px 5px 0 0;
            }
            span {
                font-weight: bold;
            }
 
        }
        .nav-img {
            display: block;
            height: 24px;
            padding-top: 4px;
            svg {
                height: 20px;
            }
        }
    }
    @include media-breakpoint-down(xs) {
        .container {
            margin: 0;
            padding: 0 15px;        
            max-width: 100%;
            width: 100%;
        }
        li {
            width: calc(25% - 12px);
            margin: 0 8px !important;
            &:first-of-type {
                margin-left: 0 !important;
            }
            &:last-of-type {
                margin-right: 0 !important;
            }
        }
        a {
            padding: 14px 0 10px 0;
        }  
    }
}

.header-logo {
    a:focus {
        @include bootstrap-focus-shadow(white);
        border-radius: 0;
    }
}

.ancestry-header {
    .header-logo {
        a:focus {
            @include bootstrap-focus-shadow-light;
            border-radius: 0;
        }   
    }
}

// @include media-breakpoint-up(xs) { ... }
// @include media-breakpoint-up(sm) { ... }
// @include media-breakpoint-up(md) { ... }
// @include media-breakpoint-up(lg) { ... }
// @include media-breakpoint-up(xl) { ... }
@import '../../index';

.referralBanner {
  background: #c6e2ed;
  background-image: url('./svg/background.svg');
  background-repeat: no-repeat;
  background-size: 347px auto;
  background-position: bottom -54px right -142px;
  @include media-breakpoint-up(sm) {
    background-size: 550px auto;
    background-position: top -60px right -195px;
  }
}

.header {
  font-size: 19px;
  @include media-breakpoint-up(sm) {
    padding-right: 200px;
  }
}

.message {
  padding-right: 100px;
  @include media-breakpoint-up(sm) {
    padding-right: 200px;
  }
}

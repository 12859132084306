//bootstrap overwrite

.container{
    @include media-breakpoint-up(lg) {
        max-width: 1200px;
        width: 96%;
    }
    @include media-breakpoint-up(xl) {
        max-width: 1440px;
        width: 96%;
    }
}

a, .btn-link {
    padding: 0;
    margin: 0;
    color: $brand-primary-color;
    background: transparent;
    border-radius: none;
    &:hover {
        color: $brand-primary-color-dark;
        background: transparent !important;
        text-decoration: none;
    }
    &:focus, &:active {
        color: $brand-primary-color;
        background: transparent;
        text-decoration: none;
    }
    &:focus {
        @include bootstrap-focus-shadow();
    }
}

a.bg-primary:hover, a.bg-primary:focus, button.bg-primary:hover, button.bg-primary:focus {
    background: $background-primary-color !important;
}

// restore hover background color for bootstrap buttons with href
a.btn-primary:hover {
  background-color: $brand-primary-color-dark !important;
}

.btn-primary {
    color: #fff;
    background-color: $brand-primary-color;
    border-color: $brand-primary-color;
    &:hover {
        background-color: $brand-primary-color-dark ;
        border-color: $brand-primary-color-dark;
    }
    &:focus, &:active {
        background-color: $brand-primary-color;
        border-color: $brand-primary-color;
    }
    &:focus {
        @include bootstrap-focus-shadow;
        border: 1px solid white;
    }
    @include media-breakpoint-down(sm) {
        &:hover {
            background-color: $brand-primary-color;
            border-color: $brand-primary-color;
        }
        &:focus, &:active {
            background-color: $brand-primary-color;
            border-color: $brand-primary-color;
        }
        &:focus {
            box-shadow: none;
            border-color: $brand-primary-color;
        }
    }
}

.btn-outline-primary {
    color: $brand-primary-color;
    border-color: $brand-primary-color;
    &:focus, &:active {
        color: $brand-primary-color;
        border-color: $brand-primary-color;
    }
    &:hover {
        color: #fff;
        background-color: $brand-primary-color-dark;
        border-color: $brand-primary-color-dark;
    }
}

.btn-secondary {
    background-color: $background-primary-color;
    color: $brand-primary-color !important;
    border: none;
    &:focus, &:active {
        background-color: $background-primary-color;
        color: $brand-primary-color !important;
        border: none;
    }
    &:hover {
        color: #fff !important;
        background-color: $brand-primary-color !important;
    }
    &.dark-hover {
        &:hover {
            background-color: black !important;
        }
    }
}

.small-gutters {
    margin-right: -5px !important;
    margin-left: -5px !important;
    > [class*="col-"] {
        padding-right: 5px !important;
        padding-left: 5px !important;
    }
}

.rounded {
    border-radius: $border-radius !important;
}

select.form-control {
    height: calc(1.5em + 0.75rem + 12px);
    &, &:focus {
        background: #fff url("data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 width=%274%27 height=%275%27 viewBox=%270 0 4 5%27%3e%3cpath fill=%27%23343a40%27 d=%27M2 0L0 2h4zm0 5L0 3h4z%27/%3e%3c/svg%3e") no-repeat right 0.75rem center/8px 10px;
        background-color: #EBF0FA;
        border: none;
        -webkit-appearance: none;
    }
}

input.form-control:focus {
    @include bootstrap-focus-shadow;
}

button.action-card,
button.survey-card {
    cursor: pointer;
    width: 100%;
    border: none;
    font-weight: inherit;
    font-size: inherit;
    line-height: inherit;
    font-family: inherit;
    @include media-breakpoint-up(md) {
        @include display-flex;
        @include flex-direction(column);
        @include align-items(center);
        @include justify-content(flex-start);
    }
    &:hover,
    &:focus,
    &:active {
        background: white !important;
    }
    p {
        margin-bottom: 0;
    }
}
.action-card-img-wrapper,
.survey-card-img-wrapper {
    background: $background-primary-color;
    display: inline-block;
    border-radius: 50%;
    text-align: center;
}
// @include media-breakpoint-up(md) {
.action-card-wrapper,
.survey-card-wrapper {
    @include display-flex;
}
h2 {
    color: $brand-primary-color;
}
.action-card,
.survey-card {
    text-align: center;
    @include media-breakpoint-up(md) {
        .action-card-mobile-chevron {
            display: none;
        }
    }
    .action-card-img,
    .survey-card-img {
        text-align: center;
        width: 100%;
        margin: $spacer * 1.5 0;
        @media only screen and (min-width: 992px) and (max-width: 1080px) {
            margin: $spacer * 1.2 0;
        }
        .action-card-img-wrapper,
        .survey-card-img-wrapper {
            width: 105px;
            height: 105px;
            padding: $spacer * 1.3;
            svg {
                width: 62px;
                height: 62px;
            }
        }
    }
    .action-card-description,
    .survey-card-description {
        width: 100%;
        @include display-flex;
        @include flex-direction(column);
    }
}

.survey-card-complete-retake-survey {
    display: block;
    color: $brand-tertiary-color;
    font-size: 12px;
    text-decoration: underline;
}

@include media-breakpoint-down(sm) {
    .survey-card-img-wrapper {
        border: 1px solid #ebf0fa;
        background-color: transparent;
        width: 80px !important;
        height: 80px !important;
        padding: 1.2rem !important;
        svg {
            width: 40px !important;
            height: 40px !important;
        }
    }

    .survey-card-link {
        display: none;
    }
    .survey-title {
        font-weight: bold !important;
        color: $copy-primary-color;
        font-size: 15px;
    }
    .survey-description {
        display: none;
    }
    .survey-description2 {
        font-weight: normal;
        color: $brand-primary-color;
        font-size: 14px;
    }
    .action-card {
        text-align: left;
        padding-right: $spacer * 2 !important;
        // @include display-flex;
        // @include align-items(center);
        .action-card-link {
            display: none;
        }
        .action-card-mobile-chevron {
            position: absolute;
            right: $spacer * 2;
            top: 50%;
            transform: translateY(-50%);
        }
        .action-card-img {
            // float: left;
            width: 50px;
            margin: 0;
            margin-right: $spacer;
            .action-card-img-wrapper {
                width: 42px;
                height: 42px;
                padding: $spacer * 0.55;
                svg {
                    width: 25px;
                    height: 25px;
                }
            }
        }
    }
}

@import '~bootstrap/scss/bootstrap';
@import '~bootstrap/scss/mixins/_breakpoints';
@import 'src/styles/base/_variables.scss';

.title {
  font-size: 26px !important;
  padding: 64px 35px;
  @include media-breakpoint-up(sm) {
    font-size: 40px !important;
    padding: 32px 16px;
  }
  @include media-breakpoint-up(md) {
    font-size: 48px !important;
    padding: 64px 16px;
  }
}

.upperSection {
  background-image: url('./svg/character-03.svg'), url('./svg/character-04.svg');
  background-position: left -104px top 28px, right -127px top 5px;
  background-size: 242px;
  background-repeat: no-repeat;
  background-origin: border-box;
  @include media-breakpoint-up(sm) {
    background-size: 400px;
    background-position: left -166px top 15px, right -210px top 0px;
  }
  @include media-breakpoint-up(md) {
    background-size: 560px;
    background-position: left -158px top 63px, right -180px top 40px;
  }
}

.section1 {
  border-radius: 16px 16px 0 0;
  padding: 16px 16px 130px;
  min-height: 660px;
  h2 {
    margin: 40px 20px 53px;
    font-size: 26px;
    line-height: 34px;
  }
  p {
    font-size: 14px;
    margin-bottom: 35px;
  }
  @include media-breakpoint-up(sm) {
    border-radius: 50px 50px 0 0;
    max-width: 550px;
    margin: auto;
    padding: 40px 40px 130px;
    h2 {
      margin: 20px 20px 40px;
      font-size: 28px;
      line-height: 37px;
    }
  }
  @include media-breakpoint-up(md) {
    border-radius: 80px 80px 0 0;
    max-width: 700px;
    margin: auto;
    padding: 60px 56px 130px;
    h2 {
      font-size: 28px;
      line-height: 37px;
    }
  }
  @include media-breakpoint-up(lg) {
    max-width: 740px;
  }
}

.section1Buttons {
  margin: 40px 0;
  text-align: center;
  gap: 20px;
  @include media-breakpoint-up(md) {
    gap: 53px;
  }
}

.section1Link {
  text-decoration: none !important;
  font-size: 14px !important;
  color: $brand-primary-color !important;
  font-weight: 600;
}

.bottomSection {
  background-image: url('./svg/bubbles-left.svg'),
    url('./svg/bubbles-right.svg'), url('./svg/referral-landing-background.svg');
  background-repeat: no-repeat;
  background-size: 200px, 400px, cover;
  background-position: left 0% top 0%, right 0% top 400px, center top;
  padding: 0 15px;
  font-size: 14px;
  margin-top: -140px;
  @include media-breakpoint-up(md) {
    background-image: url('./svg/bubbles-left.svg'),
      url('./svg/bubbles-right.svg'), url('./svg/background-desktop.svg');
    background-size: 400px, 800px, cover;
    background-position: left 0% top -77px, right 0% top -135px, center top;
  }
  h2 {
    margin: 40px 15px;
    font-size: 26px;
    line-height: 34px;
    @include media-breakpoint-up(md) {
      margin: 120px 15px;
      font-size: 28px;
      line-height: 37px;
    }
  }
}

.section2 {
  h3 {
    font-size: 22px;
    font-weight: 400;
    color: #2f281e;
    line-height: normal;
    margin: 0 15px 15px;
  }
}

.cards {
  @include media-breakpoint-up(md) {
    text-align: left;
    display: flex;
    justify-content: center;
    gap: 20px;
    h3 {
      margin: 0 0 16px;
    }
    > div {
      flex: 0 1 25%;
    }
    max-width: 800px;
    margin: 0 auto;
  }
  @include media-breakpoint-up(lg) {
    gap: 40px;
  }
}

.section3 {
  h2 {
    font-size: 26px;
    line-height: 34px;
    font-weight: 400;
    color: #342d20;
    margin: 0 15px 15px;
    @include media-breakpoint-up(md) {
      margin: 100px 15px 15px;
    }
  }
  h3 {
    font-size: 22px;
    line-height: 30px;
    font-weight: 600;
    color: #2f281e;
    margin: 0 15px 15px;
    @include media-breakpoint-up(md) {
      margin: 0 0 20px;
    }
  }
}

.section3Content {
  @include media-breakpoint-up(md) {
    display: flex;
    gap: 45px;
    text-align: left;
    max-width: 800px;
    margin: 0 auto 120px;
  }
    > div {
      flex: 0 1 50%;
    }
}

.bottomSectionButton {
  padding: 0 0 16px 0;
  @include media-breakpoint-up(md) {
    padding: 0 0 75px 0;
  }
}

.footer {
  gap: 10px;
  padding: 16px 0 60px;
  a {
    color: $brand-primary-color !important;
  }
  @include media-breakpoint-up(md) {
    padding: 16px 16px 60px;
    ul {
      margin: 0;
    }
  }
  @include media-breakpoint-up(lg) {
    padding: 16px 40px 60px;
  }
}

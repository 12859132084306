footer {
    &.white {
        background: white;
    }
    ul {
        list-style-type: none;
        padding-left: 0;
        li {
            display: inline-block;
            position: relative;

            &:after {
                content: "";
                width: 5px;
                height: 5px;
                border-radius: 50%;
                background: black;
                display: inline-block;
                margin: 0 10px 2px 10px;
            }

            &:last-of-type {
                margin-right: 0;
                &:after {
                    display: none;
                }
            }
            @include media-breakpoint-down(sm) {
                &:nth-of-type(2) {
                    margin-right: 0;
                    &:after {
                        display: none
                    }
                }
            }
        }
    }
    img {
        height: 51px;
        object-fit: scale-down;
    }
    .desktop-only {
        @include media-breakpoint-down(lg) {
            display: none !important;
        }
    }
    .mobile-only {
        @include media-breakpoint-down(sm) {
            display: block !important;
        }
        @include media-breakpoint-up(md) {
            display: none !important;
        }
    }

    &.footer-unauth {
        position: absolute;
        padding-right:30px;
        padding-left:30px;
        width:100%;
        bottom:0px;

        @include media-breakpoint-down(sm) {
            bottom:0px;
            padding-right:20px;
            padding-left:20px;
            margin-bottom: 0px;
        }

        .terms-copy {
            color:#fff;
            text-align: left;
            padding-top: 10px;
            @include media-breakpoint-down(md) {
               font-size: 9px;
            }
        }

        .rgc-logo {
            text-align: right;
            @include media-breakpoint-down(md) {
               img {
                   width: 137px;
               }
            }
        }
    }
}

.ancestry-footer {
    @include media-breakpoint-up(md) {
        position: absolute;
        bottom: 0;
        width: 100%;
        display: none;
    }
    @media only screen and (max-height: $mediaHeight) {
        position: absolute;
        padding-top: 160px !important;
    }

    @include media-breakpoint-down(sm) {
        padding-top: 1rem !important;
        width: 100%;
        padding-bottom: 150px;
        position: absolute;
        bottom: auto;
    }
}

.unauth-footer {
    a, a:focus, a:active {
        color: white;
    }

    .unauth-footer-separator {
        content: "";
        width: 5px;
        height: 5px;
        border-radius: 50%;
        background: white;
        display: inline-block;
        margin: 0 10px 2px 10px;
        // padding: 0 $spacer * 0.5;
        @include media-breakpoint-down(sm) {
            margin: 0 5px 2px 5px;
            width: 3px;
            height: 3px;
        }
    }
}

.web-page-footer {
    background: #ebf0fa;
    position: relative;

    ul {
        list-style-type: none;
        padding-left: 0;
        li {
            display: inline-block;
            position: relative;

            &::after {
                content: "";
                width: 5px;
                height: 5px;
                border-radius: 50%;
                background: black;
                display: inline-block;
                margin: 0 10px 2px 10px;
            }

            &:last-of-type {
                margin-right: 0;
                &::after {
                    display: none;
                }
            }

            @include media-breakpoint-down(sm) {
                &:nth-of-type() {
                    margin-right: 0;
                    &::after {
                        display: none;
                    }
                }
            }
            
        }
    }
}

.web-page-footer::before {
    content: "";
    position: absolute;
    background-color: #EBF0FA;
    width: 100%;
    height: 1rem;
    border-radius: 60%;
    top: 0;
    transform-origin: top;
    transform: translate(0, -50%);
}

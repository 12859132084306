@import '../../../styles/base/variables.scss';

.workflowComponentRenderer {
  background-color: white;
  border-radius: $border-radius;
  padding-right: 3rem;
  padding-left: 3rem;
  padding-bottom: 2rem;

  &.h350 {
    min-height: 350px;
  }
}

input[type='text'],
input[type='password'],
input[type='email'],
input[type='number'],
input[type='tel'] {
    border: none !important;
    background-color: $background-primary-color !important;
    padding: 24px 12px !important;
    font-size: 18px;
    color: $form-input-color !important;
    @include media-breakpoint-down(sm) {
        font-size: 16px;
    }
}
label, .label {
    font-weight: bold;
    color: $brand-primary-color;
    font-weight: bold;
    margin: 0;
}
.label {
    font-size: $body-copy-size;
}

.checkbox-toggle {
    // visibility: hidden;         
    opacity: 0;
    position: absolute;
    // top: -99999px;
    &:focus {
        &+ .checkbox-toggle-label {
            @include bootstrap-focus-shadow();
        }
    }
}
.checkbox-toggle + .checkbox-toggle-label {
    font-family: arial;
    vertical-align: middle;
    width: 40px;
    height: 20px;
    display: inline-block;
    position: relative;
    border-radius: 15px;
    outline: none;
    border: none;
    cursor: pointer;
    background-color: $background-primary-color;
    -webkit-transition: .25s ease-in-out;
    -moz-transition: .25s ease-out;
    -o-transition: .25s ease-out;
    transition: .25s ease-out;
}

.checkbox-toggle + .checkbox-toggle-label:before {
    width: 22px;
    height: 22px;
    top: -1px;
    left: -1px;
    border-radius: 12px;
    z-index: 2;
    content: "";
    display: block;
    position: absolute;
    background-color: darken($border-color, 20%);
    font-size: 14.99px;
    font-weight: 700;
    text-transform: uppercase;
    text-indent: -32px;
    word-spacing: 40px;
    color: #fff;
    white-space: nowrap;
    -webkit-transition: .25s ease-out;
    -moz-transition: .25s ease-out;
    -o-transition: .25s ease-out;
    transition: .25s ease-out;
}

.checkbox-toggle:checked + .checkbox-toggle-label:before, .state-on:before {
    background-color: $brand-primary-color;
    left: 21px;
}

.radio-square {
    .radio-square-wrapper {
        display: block;
        position: relative;
        cursor: pointer;
        -webkit-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        user-select: none;
    }
    .radio-square-wrapper input {
        position: absolute;
        opacity: 0;
        cursor: pointer;
    }
    .radio-square-wrapper .checkmark {
        position: relative;
        top: 0;
        left: 0;
        height: 25px;
        width: 25px;
        border: 2px solid $border-color;;
        border-radius: 4px;
    }
    .radio-square-wrapper input:checked ~ .checkmark {
        background-color: $brand-primary-color;
        border: 2px solid $brand-primary-color;
    }
    .radio-square-wrapper .checkmark:after {
        content: "";
        position: absolute;
        display: none;
        top: 1px;
        left: 7px;

        transform: rotate(45deg);
        height: 15px;
        width: 8px;
        border-bottom: 3px solid white;
        border-right: 3px solid white;
    }
    .radio-square-wrapper input:checked ~ .checkmark:after {
        display: block;
    }
    .radio-square-wrapper input:focus ~ .checkmark {
        @include bootstrap-focus-shadow;
        border-color: white;
    }
}

.security-policies-input {
    // edge case when app policies onboard is triggered after onboard flow the input gets stuck...
    .state-off:before {
        background-color: darken($border-color, 20%) !important;
        left: -1px !important;
    }
    .state-on:before {
        background-color: $brand-primary-color !important;
        left: 21px !important;
    }
}

.password-form {
    .password-requirements {
        @include media-breakpoint-up(sm) {
            padding-right: 2rem;
        }
    }
    ul {
        padding-left: 0;
        list-style-type: none;
        @include media-breakpoint-down(sm) {
            list-style-type: disc;
            padding-left: 16px;
        }
        li {
            position: relative;
            padding-right: 15px;
        }
        .password-checkmark {
            background: $color-success;
            display: inline-block;
            width: 11px;
            height: 11px;
            border-radius: 50%;
            padding: 3px 1px;
            margin-left: 4px;
            position: absolute;
            top: 3px;
            right: 0;
            @include media-breakpoint-down(sm) {
                top: 2px;
            }
            &:before {
                content: "";
                display: block;
                width: 6px;
                height: 4px;
                margin-left: 2px;
                transform: rotate(-45deg);
                border-left: 2px solid white;
                border-bottom: 2px solid white;
            }
        }
    }
}


.form-error {
    min-height: 15px;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

//
// Brand Colours (mostly used for copy).
//
$brand-primary-color: #005CA3;
$brand-primary-color-dark: #1A1A1D;
$brand-secondary-color: #94CEE5;
$brand-tertiary-color: #007AB8;
$brand-high-contrast-color: white;
$brand-high-contrast-link-color: #ADD9EB;

$highlight-color-1: #CBF8FE;
$highlight-color-2: #FFE8AC;
$highlight-color-3: #EEBAD4;
$highlight-color-4: #9894EB;

$color-success: #7AD157;

$copy-primary-color: #1A1A1D;

$background-primary-color: #EBF0FA;
$background-secondary-color: #FFF;
$background-tertiary-color: #d0dff6;

$border-color: #D8D8D8;

$border-radius: 0.5rem;

$footerHeight: 30px;
$mobileNavHeight: 82px;

$body-copy-size: 16px;

$form-input-color: $copy-primary-color; // #6399c5;

$heightHeader: 60px;
$heightBodyTopPadding: 3rem;
$heightProgressBar: 80px;
$heightFooter: 120px;

$mediaHeight: 900px;

$default-width-sm: 600px;

$buttonTransition: color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out;

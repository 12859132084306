.info-popup-button {
    display: inline-block;
    padding: 0;
    width: 24px;
    height: 24px;
    line-height: 1;
    vertical-align: top;
    border-radius: 50%;
    border: none;
    background: url('../../assets/icons/info.svg') #feeaaf no-repeat center;

    &.static {
        pointer-events: none !important;
    }

    &:hover {
        background-color: #afdcff !important;
    }

    &:focus {
        background-color: #afdcff !important;
        border: none !important;
        outline: none !important;
        border-radius: 50%;
        box-shadow: none;
    }

    &:active {
        background-color: #feeaaf !important;
    }
}

.info-modal {
    .modal-dialog {
        max-width: 720px;
    }
    .modal-content {
        width: calc(100% - 1rem);
    }
    h2 {
        color: black;
        font-size: 32px;
    }
}

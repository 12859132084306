.quick-polls {
    .h1 {
        font-size: 36px; 
    }
    .quick-poll-button, .quick-poll-button:focus, .quick-poll-button:active {
        color: #005ca3 !important;
        background: #ebf0fa !important;
        border: none !important;
        &:hover {
            background: #005ca3 !important;
            color: white !important;
        }
        &:focus, &.focus {
            @include bootstrap-focus-shadow(!important);
            &:hover {
                padding: $spacer * .9 !important;
                border: solid $spacer * .1 white !important;
            }
        }
    }
    .quick-poll-question {
        p {
            font-size: 18px;
            font-weight: bold;
        }
        @include media-breakpoint-up(md) {
            border-right: 1px solid $border-color;
        }
    }
    .quick-poll-answer, .quick-poll-survey {        
        @include media-breakpoint-up(md) {
            min-height: 310px;
        }
    }
    .quick-poll-wrapper, .quick-poll-survey {
        @include media-breakpoint-down(sm) {
            min-height: 390px;
        }
    }
    .quick-polls-skip {
        min-height: 40px;
        @include media-breakpoint-up(md) {
            border-top: 1px solid $border-color;
        }
    }
    .answer-title {
        padding-right: 40px;
    }
    @include media-breakpoint-down(xs) {
        .quick-polls-card-wrapper  {
            position: relative;
        }
        .has-forward:after, .has-back:before {
            content: "";
            height: 100%;
            width: 10px;
            position: absolute;
            top: 0;
            background: white;
        }
        .has-forward:after {
            right: -18px;
            border-radius: $spacer * 0.5 0 0 $spacer * 0.5;
        }
        .has-back:before {
            left: -18px;
            border-radius: 0 $spacer * 0.5 $spacer * 0.5 0;
        }
    }


    .quick-poll-answer-graph {
        position:relative;
        // overflow: hidden;
        .answer-title {
            position: relative;
            z-index: 2;
            color: $brand-primary-color;
        }
        .results-graph { 
            position:absolute;
            top:0;
            right:100%;
            bottom:0;
            left:0;
            z-index: 1;
            .results-animation {
                // border-radius: $border-radius 0 0 $border-radius ;
                position:absolute;
                top:0;
                right:100%;
                bottom:0;
                left:0;
                width: 100%;
                opacity: 0.1;
                background: $brand-primary-color; 
                animation: resultsBar 2s ease;
                border-radius: 0.5rem 0 0 0.5rem !important;
                @keyframes resultsBar {
                    0% {
                        left:0%;
                        right:100%;
                        width:0%;
                    }
                    100% {
                        left:0%;
                        right:100%;
                        width:100%;
                    }
                }
            }
        }
        .results-percentage {
            position: absolute;
            right: 10px;
            top: 50%;
            transform: translateY(-50%);
            color: $brand-primary-color;
            // opacity: 0.5;
            font-weight: bold;
            // font-size: 20px;
            z-index: 2;
        }

    }
    .selected {
        background: $background-primary-color;
        // color: white;
        .results-animation {
            background: $highlight-color-1 !important;
            opacity: 1 !important;
        }
    }
}

.quick-polls-survey-wrapper + .quick-polls-skip .continue-with-quick-polls {
    @include media-breakpoint-up(md) {    
        position: absolute; 
        // bottom: 140px;
        bottom: 166px;
        left: calc(48px + 200px + 1rem);
        text-align: left !important;
        z-index:2;
    }
}

.quick-polls-survey-wrapper-2 + .quick-polls-skip .continue-with-quick-polls {
    @include media-breakpoint-up(md) {    
        bottom: 139px;
    }
}

.quick-polls-survey-wrapper-1 + .quick-polls-skip .continue-with-quick-polls {
    @include media-breakpoint-up(md) {    
        bottom: 108px;
    }
}

.quick-polls-survey-wrapper-no-survey + .quick-polls-skip .continue-with-quick-polls {
    @include media-breakpoint-up(md) {    
        position: static;
        text-align: right !important;
    }
}

.quick-polls-card-survey {
    background: $brand-primary-color !important; 
    overflow: hidden;
    position: relative;
    .quick-poll-survey {
        background: $brand-primary-color;
        .h1, .h2 {
            color: white;
        }    
        .h2 {
            font-size: 28px;
        }
        .quick-poll-survey-wrapper {
            width: 100%;
            position: relative;
            .quick-poll-survey-content {
                z-index: 2;
                position: relative;
                @include media-breakpoint-down(sm) {
                    h1 {
                        font-size: 32px !important;
                        line-height: 36px;
                    }
                    h2 {
                        font-size: 24px;
                    }
                }
            }
        }
        .quick-poll-survey-button {
            border: none;
            background: white;
            color: $brand-primary-color !important;
            padding: 12px;
            width: 200px;
            @include media-breakpoint-down(xs) {
                width: calc(100vw - 4rem);
                position: absolute;
                left: 0.5rem;
                bottom: -50px;
            }
            &:hover {
                background-color: $brand-secondary-color !important;
            }
        }
        .quick-poll-survey-icon {
            .quick-poll-survey-icon-wrapper {
                $desktopWidth: 70px;
                $mobileWidth: 60px;
                width: $desktopWidth;
                margin: 0 auto;
                svg {
                    width: $desktopWidth;
                    height: $desktopWidth;
                }
                @include media-breakpoint-down(sm) {
                    width: $mobileWidth;
                    svg {
                        width: $mobileWidth;
                        height: $mobileWidth;
                    }
                }
                .survey-icon {
                    background: white;
                    padding: 10px;
                    border-radius: 50%;
                    width: $desktopWidth;
                    height: $desktopWidth;
                    margin-top: $spacer * 2;
                    svg {
                        width: calc(#{$desktopWidth} - 20px);
                        height: calc(#{$desktopWidth} - 20px);
                    }
                    @include media-breakpoint-down(sm) {
                        width: $mobileWidth;
                        height: $mobileWidth;
                        margin-top: $spacer * 4;
                        margin-bottom: $spacer;
                        svg {
                            width: calc(#{$mobileWidth} - 20px);
                            height: calc(#{$mobileWidth} - 20px);
                        }
                    }
                }
            }
        }
    }
    .quick-polls-skip {
        border-color: transparent !important;
    }
    .continue-with-quick-polls button {
        color: $brand-secondary-color;
        font-weight: normal;
        text-decoration: underline;

        @include media-breakpoint-down(sm) {   
            color: $highlight-color-1;
        }

    }
    .quick-poll-survey-bg {
        pointer-events: none;
        svg {
            width: 1100px;
            height: 1093px;
            position: absolute;
            top: -300px;
            left: -280px;
        }
    }
    .quick-poll-survey-img-1 {        
        pointer-events: none;
        svg {
            width: 110px;
            height: 110px;
            position: absolute;
            top: 90px;
            left: 30px;
            @include media-breakpoint-down(sm) {
                width: 130px;
                height: 130px;
                top: 14px;
                left: 70px;
                transform: rotate(-90deg);
            }
        }
    }
    .quick-poll-survey-img-2 {
        svg {
            width: 600px;
            height: 456px;
            position: absolute;
            top: -20px;
            right: 0;
            @include media-breakpoint-down(sm) {
                width: 600px;
                height: 456px;
                position: absolute;
                top: -20px;
                right: -133px;
            }
        }
    }
    .quick-poll-survey-chara {
        svg {
            width: 240px;
            height: 464px;
            position: absolute;
            top: -20px;
            right: 0;
            @include media-breakpoint-down(sm) {
                right: -140px;
            }
        }
    }
}
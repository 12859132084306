$ancestry-header-gutter: 5px;

.ancestry-header {
    position: absolute;
    top: $ancestry-header-gutter;
    left: $ancestry-header-gutter;
    background: white;
    border-radius: $border-radius;
    z-index: 3;
    width: calc(100% - #{$ancestry-header-gutter} - #{$ancestry-header-gutter});
    padding-top: calc(1.5rem - #{$ancestry-header-gutter});
    padding-bottom: calc(1.5rem - #{$ancestry-header-gutter} + 8px); // extra px for language
    @include media-breakpoint-down(sm) {
        // @media (orientation: landscape) {
            background: transparent;
            pointer-events: none;
            a, button {
                position: relative;
                z-index: 3;
                pointer-events: all; 
            }
        // }
    }
    nav a:focus {
        @include media-breakpoint-up(md) {
            border-width: 0.1rem;
            border-color: $brand-high-contrast-color;
            border-style: solid;
            padding: $spacer * .5 - .1 $spacer - .1;
            @include bootstrap-focus-shadow();
        }
    }
    nav a, nav a:focus, nav a:active {
        color: $brand-primary-color;
    }
    nav a.active {
        @include media-breakpoint-up(md) {
            color: white !important;
            background: $brand-primary-color !important;
         }
    }

    .nav-name {
        color: $brand-primary-color;
    }
}

.ancestry-header + .auth-content {
    padding: 0 !important;
}

.inlineText {
    white-space: nowrap;
}

.embed-responsive, .embed-responsive-item, .mapboxgl-canvas {
    height: 100vh !important;
    width: 100vw;
}

.ancestry {
    padding-bottom: $spacer;
    .color-legend {
        position: absolute;
        border-radius: 50%;
        transform: translateY(-50%);
        top: 50%;
        left: 10px;
        width: 20px; 
        height: 20px;
    }
    .ancestry-h1 {
        line-height: 34px;
    }
    .ancestry-h1-region {
        position: relative;
        padding-left: 24px;
        .color-legend {
            left: 0;
            width: 14px; 
            height: 14px;
            margin-top: -2px;
        }
    }
    .ancestry-region-description p {
        margin-top: $spacer;
    }
    .ancestry-panel {
        @include media-breakpoint-up(md) {
            position: absolute;
            top: 11rem;
            right: 1rem;
            z-index: 2;
            width: 40vw;
            max-width: 320px;
            @media only screen and (max-height: $mediaHeight) {
                margin-top: -290px;
                position: relative;
                margin-left: auto;
                margin-right: auto;
                max-width: 100%;
                width: calc(100vw - 2rem);
                left: 0
            }
        }
        @include media-breakpoint-down(sm) {
            margin-top: -260px;
            position: relative;
            margin-left: auto;
            margin-right: auto;
            @media (orientation: landscape) {
                margin-top: -170px !important;
            }
        }

        .ancestry-controls {
            background: white;
            padding: $spacer*1.5;
            border-radius: $border-radius;
        }
        .btn-primary-invert {
            background: $background-primary-color;
            color: $brand-primary-color;
        }
        .ancestry-controls-button {
            background: transparent;
            font-weight: normal;
            color: $brand-primary-color;
            padding-right: 42px;
            line-height: 20px;
            padding-top: 12px;
            padding-bottom: 12px;
            border-radius: 0;
            word-wrap: break-word;
            // border-top: 1px solid $border-color;
            &:before {
                content: "";
                height: 1px;
                width: calc(100% - 20px);
                background: $border-color;
                position: absolute;
                top: 0;
                left: 10px;
            }
            .region-percentage {
                position: absolute;
                right: 10px;
                top: 50%;
                transform: translateY(-50%);
                font-weight: normal;
            }
            &.selected {
                font-weight: bold;
            }
        }
        .ancestry-list .ancestry-controls-button {
            text-align: left !important;
            position: relative;
        }
        .ancestry-country {
            background: $background-primary-color;
            border-radius: $border-radius;
            & > button:before {
                display: none;
            }
        }
        .ancestry-controls-button-level1 {
            padding-left: 40px;
        }
        .ancestry-controls-button-level2 {
            padding-left: 46px !important;
            .color-legend {
                width: 16px;
                height: 16px;
                left: 14px;
            }
        }
        .ancestry-controls-button-level3 {
            padding-left: 52px;
            .color-legend {
                width: 14px;
                height: 14px;
                left: 18px;
            }
        }
        .ancestry-controls-button-level4 {
            padding-left: 60px;
            .color-legend {
                width: 12px;
                height: 12px;
                left: 24px;
            }
        }
        .ancestry-zoom-out {
            // $zoomButtonSize: 25px;
            border: 0;
            padding: 12px;
            background: none;
            position: absolute;
            top: -20px;
            right: 5px;
            font-weight: normal;
            color: $brand-primary-color;
            &.inactive {
                color: #888;
                pointer-events: none;
            }
            @include media-breakpoint-down(sm) {
            //    right: 1.5rem;
            //    top: 1.5rem;
            }
            // svg {
            //     width: $zoomButtonSize;
            //     height: $zoomButtonSize;
            //     fill: $brand-primary-color;
            // }
            &:focus {
                outline: none !important;
                border: none m !important;
                box-shadow: none !important;
            }
        }

    }  
}

.mapboxgl-control-container {
    display: none;
}


.ancestry-locked-map-cover {
    width: 100vw;
    height: 100vh;
    position: absolute;
    top: 0;
    left: 0;
    background: $background-primary-color;
    opacity: 0.5;
}
.ancestry-locked {
    position: absolute;
    z-index: 2;
    top: 140px;
    left: 50%;
    transform: translateX(-50%);
    text-align: center;
    width: 50%;
    min-width: 400px;
    @include media-breakpoint-down(md) {
        top: 100px;
    }
    @include media-breakpoint-down(sm) {
        position: static;
        width: 100%;
        min-width: 100%;
        transform: none;
    }

    .ancestry-locked-box-wrapper {

        @include media-breakpoint-down(sm) {
            position: absolute;
            top: 350px;
            left: 0;
            width: calc(100% - 2rem);
            margin: 0 1rem;
        }

        .ancestry-locked-icon-container {
            & svg {
                width: 250px;
                height: 250px; 
                @include media-breakpoint-down(sm) {
                    position: absolute;
                    transform: translate(-50%, -100%);
                    width: 200px;
                    height: 200px;
                    z-index: 2;
                }
            }
        }

        .ancetry-locked-content-title {
            text-align: center;
            margin-bottom: 1rem;
            color: $brand-primary-color;
        }

        .ancetry-locked-content-subtitle {
            text-align: center;
            margin-bottom: 1rem;
            color: #666666;
        }
        
        .ancetry-conset-content-subtitle{
            text-align: left;
            margin-bottom: 1rem;
            color: #666666;
        }

        .ancestry-consent-content {
            align-items: center;
            flex-direction: row;
        }

        .ancestry-locked-content {
            background-color: white;
            border-radius: 0 0 0.5rem 0.5rem;
            padding: 25px;
            position: relative;
            text-align: left;
            @include media-breakpoint-down(lg) {
                width: 130%;
                left: -15%;
                margin-top: 30px;
            }
            @include media-breakpoint-down(md) {
                width: 140%;
                left: -20%;
                margin-top: 20px;
            }
            @include media-breakpoint-down(sm) {
                width: calc(100% - 2rem);
                margin: 0 1rem;
                position: static;
            }
            
        }
        .ancestry-locked-content::before {
            content: '';
            width: 100%;
            position: absolute;
            height: 125px;
            z-index: -1;
            border-radius: 0.5rem 0.5rem 0 0;
            transform: translateY(-125px);
            left: 0;
            top: 0;
            background-color: white;

            @include media-breakpoint-down(sm) {
                content: '';
                width: calc(100% - 2rem);
                margin: 0 1rem;
                position: absolute;
                height: 100px;
                z-index: 1;
                border-radius: 0.5rem 0.5rem 0 0;
                transform: translateY(-100px);
                left: 0;
                top: 0;
                background-color: white;
            }

        }
    }
    .ancestry-locked-box {
        position: absolute;
        bottom: 0;
        width: 70%;
        margin: 0 auto;
        left: 15%;
        @include media-breakpoint-down(sm) {
            position: relative;
            left: 0;
            margin: 0;
            margin-top: 140px;
            width: 100%;
        }
    }
    
    .ancestry-locked-suggested-surveys {
        width: 100%;
        position: absolute;
        margin-top: 40px;
        left: 0;
        padding-bottom: 150px;
        h2 {
            text-align: left;
            font-size: 16px;
        }
        @include media-breakpoint-down(lg) {
            width: 130%;
            left: -15%;
            margin-top: 30px;
        }
        @include media-breakpoint-down(md) {
            width: 140%;
            left: -20%;
            margin-top: 20px;
        }
        @include media-breakpoint-down(sm) {
            width: calc(100% - 2rem);
            margin: 1rem;
            position: static;
        }

        svg {
            width: 350px;
            height: 350px;    
            @include media-breakpoint-down(sm) {
                position: absolute;
                left: 50%;
                transform: translateX(-50%);
                width: 200px;
                height: 200px;
            }
        }
    }

    .ancestry-locked-btns {
        gap: 10px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        .btn-link {
            padding: 13px 30px 13px 16px;
            margin: 0;
            color: $brand-primary-color;
            background: transparent;
            border-radius: none;
            text-decoration: none;
            &:hover {
              color: $brand-primary-color-dark;
            }
            &:focus {
              @include bootstrap-focus-shadow();
            }
        }
    }    
}
.ancestry-panel-locked {
    @include media-breakpoint-down(md) {
        display: none;
    }
    @include media-breakpoint-down(sm) {
        display: block;
        width: 100%;
        position: absolute !important;
        top: 80vh !important;
        // background: red !important;
    }
    @include media-breakpoint-down(xs) {
        top: 100vh !important;
    }
}

.ancestry-referral-banner {
  position: absolute;
  top: 110px;
  z-index: 3;
  width: 100%;
  .center-section {
    margin: 0 auto;
  }
}

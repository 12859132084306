.surveys {
    .surveys-tab {
        margin: 0;
        margin-bottom: $spacer;
        display: flex;
        flex-direction: row;
        padding: 0px;

        button {
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;
            padding: 13px 30px;

            background: #ffffff;
            color: $brand-primary-color;

            /* Inside auto layout */

            flex: 1 1 0;
            margin: 0px 0px;

            // Reset borders as if buttons were divs.
            border: none;

            font-size: 16px;
            line-height: 24px;

            &:focus {
                box-shadow: none;
                &:not(.active){
                    border: 2px solid $input-btn-focus-color;
                    padding: 11px 28px;
                }
            }

            &.active {
                font-weight: bold !important;
                pointer-events: none;
                outline: none;
                color: #ffffff;
                background: $brand-primary-color;
            }
            &:first-of-type {
                border-radius: $spacer 0 0 $spacer !important;
            }
            &:last-of-type {
                border-radius: 0 $spacer  $spacer 0 !important;
            }
        }
    }
}

.survey-consent-wrapper {
    background: white;
    padding: $spacer;
    border-radius: $spacer;
    margin-bottom: $spacer;
}

.survey-consent-modal {
    .survey-consent-modal-icon {
        position: absolute;
        background: white;
        top: -60px;
        left: 50%;
        transform: translateX(-50%);
        width: 120px;
        height: 120px;
        padding: 19px;
        border-radius: 50%;
        border: 1px solid $background-primary-color;
        svg {
            width: 80px;
            height: 80px;
        }
    }
    .modal-title {
        padding-top: $spacer * 3;
    }
    .modal-header {
        padding-bottom: 0;
    }
    .primary-invert {
        margin-top: $spacer * 2;
        background: $brand-primary-color !important;
        color: white !important;
        &:focus {
            border: 1px solid white;
        }
    }
    .survey-consent-wrapper {
        background: transparent;
        padding: 0;
        border-radius: none;
        margin-bottom: 0;
    }
}

.surveys,
.survey-take,
.survey-complete {
    .right-section {
        @include media-breakpoint-down(lg) {
            display: none;
        }
    }
    .desktop-only {
        display: block !important;
        @include media-breakpoint-down(lg) {
            display: none !important;
        }
    }
    span.desktop-only {
        display: inline !important;
        @include media-breakpoint-down(lg) {
            display: none !important;
        }
    }
    .mobile-only {
        display: block !important;
        @include media-breakpoint-up(xl) {
            display: none !important;
        }
    }
    span.mobile-only {
        display: inline !important;
        @include media-breakpoint-up(xl) {
            display: none !important;
        }
    }
}

.survey-page-icon-wrapper {
    background: white;
    position: absolute;
    padding: $spacer * 2;
    border-radius: 50%;
    top: -112px;
    svg {
        width: 70px;
        height: 70px;
    }
    @include media-breakpoint-down(lg) {
        right: 60px;
        padding: $spacer * 1.5;
        top: -92px;
        svg {
            width: 50px;
            height: 50px;
        }
    }
    @include media-breakpoint-down(md) {
        right: $spacer;
    }

    @include media-breakpoint-down(sm) {
        right: $spacer;
        padding: $spacer;
        top: -82px;
        svg {
            width: 50px;
            height: 50px;
        }
    }
}

.survey-complete .suggested-surveys-wrapper .small-gutters {
    width: 100%;
}

.survey-complete-content {
    @include media-breakpoint-down(sm) {
        background: white;
        border-radius: $border-radius;
        padding: 1rem;
        margin-bottom: 2rem;
        .section-component:last-of-type {
            margin-bottom: 0 !important;
        }
    }
    .survey-complete-ancestry-link {
        @include media-breakpoint-up(md) {
            text-align: right;
            .desktop-only {
                display: inline !important;
            }
            .mobile-only {
                display: none !important;
            }
        }
        @include media-breakpoint-down(sm) {
            .desktop-only {
                display: none !important;
            }
            .mobile-only {
                display: inline !important;
            }
        }
        .btn {
            @include media-breakpoint-up(md) {
                margin-top: 1rem;
                background-color: white;
                color: $brand-primary-color;
                padding: 18px;
                padding-right: 60px;
                position: relative;
                &:after {
                    content: "";
                    position: absolute;
                    right: 18px;
                    top: calc(50% - 2px);
                    width: 10px;
                    height: 10px;
                    transform: rotate(45deg) translateY(-50%);
                    border-right: 2px solid $brand-primary-color;
                    border-top: 2px solid $brand-primary-color;
                }
                &:hover {
                    background-color: $brand-primary-color !important;
                    color: white;
                }
            }
            @include media-breakpoint-down(sm) {
                margin-top: 2rem;
                color: white;
                background-color: $brand-primary-color;
                display: block;
                &:hover {
                    background-color: $brand-primary-color-dark !important;
                    color: white;
                }
            }
        }
    }
}

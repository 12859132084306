
$cat-color-light: $background-primary-color;
$cat-color-correct: $highlight-color-1;
$cat-color-wrong: $highlight-color-3;

.questionnaire {
    .question-slide-wrapper {
        border: 1px solid $cat-color-light;
        border-radius: 8px;
        padding: 0;

        h3, h4 {
            font-weight: normal;
            color: black !important;
            margin-bottom: 10px;
        }

        h3 {
            padding: 10px 20px 0;
            font-size: 38px;
        }

        h4 {
            padding: 0 20px;
            font-size: 14px;
        }
    }

    .question-answers {
        display: block;
    }

    .question-answer-item {
        margin: 5px 10px;
        display: flex;
        position: relative;

        &:focus {
            outline: none;
            & > .custom-control {
                @include bootstrap-focus-shadow;
                &:hover {
                    border: solid 1px white;
                    padding: 18px - 1 12px - 1;
                }
            }
        }

        &.is-selected.is-correct > .check-icon {
            position: absolute;
            right: 24px;
            z-index: 1;
            top: 50%;
            width: 32px;
            height: 32px;
            display: block;
            transform: translateY(-50%);

            &::before, &::after {
                position: absolute;
                content: '';
                display: inline-block;
                width: 32px;
                height: 32px;
                border: none;
            }
            &::before {
                border-radius: 50%;
                background: #cbf8fe;
            }
            &::after {
                width: 24px;
                height: 24px;
                top: 4px;
                left: 4px;
                background: url('../../assets/icons/checkmark.svg');
            }
        }

        &.is-correct .custom-control {
            background-color: $cat-color-correct !important;
            &:hover {
                label {
                    color: $brand-primary-color !important;
                }
            }
        }

        &.is-answered .custom-control {
            pointer-events: none !important;
        }

        &.is-selected.is-wrong .custom-control {
            background-color: $cat-color-wrong !important;
        }

        &.is-selected .custom-control {
            background-color: $brand-primary-color !important;
            border: solid 1px white;
            padding: 18px - 1 12px - 1;

            &:hover label, label {
                color: white !important;
            }
            &:active {
                background-color: $cat-color-light !important;
            }
        }

        .custom-control {
            cursor: pointer;
            display: flex;
            width: 100%;
            padding: 10px;
            background-color: $cat-color-light;
            border-radius: 8px;
            transition: $buttonTransition;
            @media(hover: hover) {
                &:hover {
                    background-color: $brand-primary-color;
                    label {
                        color: white !important;
                    } 
                }
            }
            label {
                cursor: pointer;
                transition: $buttonTransition;
            }
        }

        &.with-correct-icons .custom-control label {
            padding-right: 60px !important;
        }

        input[type="radio"], label::before, label::after {
            display: none;
        }
    }

    .next-question {
        padding: 15px;
        border-top: 1px solid #EBF0FA;
    }

    .button-next-question {
        appearance: none;
        border: none;
        background: none;
        color: $brand-primary-color;
        font-weight: normal;
        padding: 0;
        display: inline-block;
        vertical-align: middle;
        position: relative;
        top: 3px;

        &[disabled] {
            opacity: 0.5;
            &:hover {
                text-decoration: none;
            }
        }

        &:hover {
            text-decoration: underline;
        }
    }

    .question-error {
        vertical-align: middle;
        font-weight: normal;
        padding: 8px 5%;
        font-weight: bold;
        min-height: 34px;
        margin-bottom: 15px;
    }
}

.signup, .consent, .signup-categorization, .signup-validation, .workflow-component {
    .questionnaire {
        .question-slide-wrapper {
            border: none;
            border-radius:0;
            padding: 0;
            margin: 0;
            h3 {
                color: $brand-primary-color;
                padding: 1rem 0;
                font-size: 32px;
                margin: 0;
            }
            h4 {
                padding: 0;
                font-size: 14px;
                margin-bottom: 20px;
            }
        }
        .question-answers {
            margin: 0 -10px;
        }
        .custom-control {
            padding: 18px 12px;
        }
        .next-question {
            border: none;
            padding: 0;
            margin-bottom: 19px;
        }
    }

    .question-answer-subtext {
        position: absolute;
        bottom: 4px;
        right: 8px;
        z-index: 1;
        font-size: 12px;
        font-style: italic;
        display: none;
    }

    .is-correct > .question-answer-subtext,
    .is-wrong > .question-answer-subtext {
        display: block;
    }

    .is-selected.is-correct > .question-answer-subtext {
        display: none;
    }
}

.consent {
    h3 {
        padding-top: 0 !important;
    }
}

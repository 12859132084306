// Flexbox
// Source: https://developer.mozilla.org/en-US/docs/Web/CSS/CSS_Flexible_Box_Layout/Mixins
@mixin display-flex {
    display: -webkit-box;
    display: -moz-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
  }
  @mixin inline-flex {
    display: -webkit-inline-box;
    display: -moz-inline-box;
    display: -webkit-inline-flex;
    display: -ms-inline-flexbox;
    display: inline-flex;
  }
  @mixin flex-direction($value: row) {
    @if $value == row-reverse {
      -webkit-box-direction: reverse;
      -webkit-box-orient: horizontal;
      -moz-box-direction: reverse;
      -moz-box-orient: horizontal;
    } @else if $value == column {
      -webkit-box-direction: normal;
      -webkit-box-orient: vertical;
      -moz-box-direction: normal;
      -moz-box-orient: vertical;
    } @else if $value == column-reverse {
      -webkit-box-direction: reverse;
      -webkit-box-orient: vertical;
      -moz-box-direction: reverse;
      -moz-box-orient: vertical;
    } @else {
      -webkit-box-direction: normal;
      -webkit-box-orient: horizontal;
      -moz-box-direction: normal;
      -moz-box-orient: horizontal;
    }
    -webkit-flex-direction: $value;
    -ms-flex-direction: $value;
    flex-direction: $value;
  }
  @mixin flex-wrap($value: nowrap) {
    // No Webkit/FF Box fallback.
    -webkit-flex-wrap: $value;
    @if $value == nowrap {
      -ms-flex-wrap: none;
    } @else {
      -ms-flex-wrap: $value;
    }
    flex-wrap: $value;
  }
  @mixin flex-flow($values: (row nowrap)) {
    // No Webkit/FF Box fallback.
    -webkit-flex-flow: $values;
    -ms-flex-flow: $values;
    flex-flow: $values;
  }
  @mixin order($int: 0) {
    -webkit-box-ordinal-group: $int + 1;
    -moz-box-ordinal-group: $int + 1;
    -webkit-order: $int;
    -ms-flex-order: $int;
    order: $int;
  }
  @mixin flex-grow($int: 1) {
    -webkit-box-flex: $int;
    -moz-box-flex: $int;
    -webkit-flex-grow: $int;
    -ms-flex: $int;
    flex-grow: $int;
  }
  @mixin flex-shrink($int: 0) {
    -webkit-flex-shrink: $int;
    -moz-flex-shrink: $int;
    -ms-flex: $int;
    flex-shrink: $int;
  }
  @mixin flex-basis($value: auto) {
    -webkit-flex-basis: $value;
    flex-basis: $value;
  }
  @mixin flex($fg: 1, $fs: 0, $fb: auto) {
    // Set a variable to be used by box-flex properties
    $fg-boxflex: $fg;
    // Box-Flex only supports a flex-grow value so lets grab the
    // first item in the list and just return that.
    @if type-of($fg) == "list" {
      $fg-boxflex: nth($fg, 1);
    }
    -webkit-box: $fg-boxflex;
    -moz-box: $fg-boxflex;
    -webkit-flex: $fg $fs $fb;
    -ms-flex: $fg $fs $fb;
    flex: $fg $fs $fb;
  }
  @mixin justify-content($value: flex-start) {
    @if $value == flex-start {
      -webkit-box-pack: start;
      -moz-box-pack: start;
      -ms-flex-pack: start;
    } @else if $value == flex-end {
      -webkit-box-pack: end;
      -moz-box-pack: end;
      -ms-flex-pack: end;
    } @else if $value == space-between {
      -webkit-box-pack: justify;
      -moz-box-pack: justify;
      -ms-flex-pack: justify;
    } @else if $value == space-around {
      -ms-flex-pack: distribute;
    } @else {
      -webkit-box-pack: $value;
      -moz-box-pack: $value;
      -ms-flex-pack: $value;
    }
    -webkit-justify-content: $value;
    justify-content: $value;
  }
  @mixin align-items($value: stretch) {
    @if $value == flex-start {
      -webkit-box-align: start;
      -moz-box-align: start;
      -ms-flex-align: start;
    } @else if $value == flex-end {
      -webkit-box-align: end;
      -moz-box-align: end;
      -ms-flex-align: end;
    } @else {
      -webkit-box-align: $value;
      -moz-box-align: $value;
      -ms-flex-align: $value;
    }
    -webkit-align-items: $value;
    align-items: $value;
  }
  @mixin align-self($value: auto) {
    // No Webkit Box Fallback.
    -webkit-align-self: $value;
    @if $value == flex-start {
      -ms-flex-item-align: start;
    } @else if $value == flex-end {
      -ms-flex-item-align: end;
    } @else {
      -ms-flex-item-align: $value;
    }
    align-self: $value;
  }
  @mixin align-content($value: stretch) {
    // No Webkit Box Fallback.
    -webkit-align-content: $value;
    @if $value == flex-start {
      -ms-flex-line-pack: start;
    } @else if $value == flex-end {
      -ms-flex-line-pack: end;
    } @else {
      -ms-flex-line-pack: $value;
    }
    align-content: $value;
  }
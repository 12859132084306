
.sig-container {
    border: 2px solid #EBF0FA;
    background: #FFF7E3;
    display: inline-block;
    width: 100%;
    // max-width: 100%;
    height: 100px;
    overflow: hidden;
    margin: 0 auto;
}

.sig-redo {
    margin-top: -6px;
    position: relative;
    button {
        font-size: 10px;
        position: absolute;
        top: -23px;
        right: 10px;
    }
}

.sig-preview {
    height: 50px;
}

.sig-container-confirm {
    margin-bottom: 1rem;
    border: 2px solid #EBF0FA;
    width: 100%;
    img {
        max-width: 100%;
    }
}